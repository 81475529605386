import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as c from "./components/globalComponents";
import Icons from "./assets/icons.svg";
import PropTypes from "prop-types";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
//require("/config/config.json");
const local = false;

const Icon = ({ name, color, width, height }) => (
  <svg
    className={`icon icon-${name}`}
    fill={color}
    width={width}
    height={height}
  >
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function split_lines(input, ring_size) {
  var newText = input.split("\n").map((str) => <p>{str}</p>);
  return (
    <c.TextDescriptionR
      sz={ring_size}
      style={{
        textAlign: "center",
        color: "var(--accent-text)",
      }}
    >
      {newText}
    </c.TextDescriptionR>
  );
}

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 1)};
  flex-direction: column;
  justify-content: "flex-start";
  align-items: "center";
  width: 100%;
  background-color: ${({ test }) => (test ? "brown" : "none")};
`;

//
export const BodyWrapperBorder = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 2)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  width: 100%;
  min-height: ${({ mobile }) => (mobile ? "0px" : "500px")};
  border-radius: ${({ mobile }) => (!mobile ? "auto" : "100px")};
  border: ${({ mobile }) => (!mobile ? "none" : "4px dashed var(--accent)")};
  box-shadow: ${({ mobile }) =>
    !mobile ? "none" : "0px 5px 11px 2px rgba(0,0,0,0.7)"};
  padding-left: ${({ mobile }) => (!mobile ? "30px" : "0px")};
  background-color: ${({ test }) => (test ? "grey" : "none")};
`;

// Used for providing a wrapper around a component
export const BodyWrapper = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 2)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  width: 100%;
  min-height: ${({ mobile }) => (mobile ? "0px" : "500px")};
  border-radius: ${({ mobile }) => (!mobile ? "auto" : "100px")};
  padding-left: ${({ mobile }) => (!mobile ? "30px" : "0px")};
  background-color: ${({ test }) => (test ? "grey" : "none")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

const SCREEN_STATES = {
  BUY: "BUY",
  MINTING: "MINTING",
  MINTED: "MINTED",
};

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  const [screenState, setScreenState] = useState(SCREEN_STATES.BUY);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your Ring.`);
  //const [feedback, setFeedback] = useState(
  //    `WOW, the CRING is yours!\ngo visit Opensea.io to view it.`
  //  );
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    LOGO: "/config/images/logo.png",
    LOGO2: "/config/images/logo_rings.png",
    SCREEN_SMALL: 800,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your\n${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong\nplease try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`WOW, the Ring is yours!\ngo visit Opensea.io to view it.`);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 2) {
      newMintAmount = 2;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch(
      local ? "/config/config_local.json" : "/config/config.json",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const ring_size = useRingSize();
  const ref = useRef(null);
  // Hook
  function useRingSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
      paddingTop: 0,
      fontSize: 20,
      fontSize2: 10,
      ratioDrift: undefined,
      mobile: window.innerWidth < CONFIG.SCREEN_SMALL,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        let refWidth = 1268;
        let refHeight = 1195;
        let topOffest = 12.5;
        let refRatio = refWidth / refHeight;
        let paddingTop = 0;
        let fontSize = 62;
        let ratioDrift = 0;
        //vertical
        if (ref.current.clientWidth / ref.current.clientHeight < refRatio) {
          ratioDrift =
            ref.current.clientWidth / ref.current.clientHeight / refRatio;
          let ringHeight = ref.current.clientHeight * ratioDrift;
          paddingTop =
            (ref.current.clientHeight - ringHeight) / 2 +
            (ringHeight * topOffest) / 100;
          fontSize = fontSize * ratioDrift * 0.8;
        }
        //horizontal
        else {
          ratioDrift =
            ref.current.clientWidth / ref.current.clientHeight / refRatio;
          if (ref.current.clientHeight < refHeight) {
            fontSize = (fontSize * ref.current.clientHeight) / refHeight;
          }

          paddingTop = (ref.current.clientHeight * topOffest) / 100;
        }

        // Set window width/height/... to state
        setWindowSize({
          width: ref.current.clientWidth,
          height: ref.current.clientHeight,
          ratioDrift: ratioDrift,
          paddingTop: paddingTop,
          fontSize: fontSize,
          fontSize2: fontSize * 0.7,
          mobile: window.innerWidth < CONFIG.SCREEN_SMALL,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  return (
    //https://css-tricks.com/almanac/properties/a/animation/
    <s.Screen
      style={{
        padding: ring_size.mobile ? 0 : 24,
        overflowY: "auto",
        alignItems: "center",
      }}
    >
    <ul id="header-container">
        <li id="logo">
          <c.Logo
            mobile={ring_size.mobile}
            logo1={CONFIG.LOGO}
            logo2={CONFIG.LOGO2}
          />
        </li>
        <li id="mower">
          <div id="lawn-mower-wrapper">
            <div id="lawn-mower-slider">
              <img
                alt={"Boost you lawn mower!"}
                src={"/config/images/lawn_mower_v.png"}
                style={{
                  width: "100px",
                }}
              ></img>
            </div>
          </div>
        </li>
      </ul>
      <s.ContainerRing
        ref={ref}
        flex={1}
        ai={"center"}
        style={{
          backgroundPosition: "50%",
          minHeight: ring_size.mobile ? 0 : "80vh",
        }}
        image={ring_size.mobile ? null : "/config/images/ring_center.png"}
      >
        <ResponsiveWrapper
          style={{
            paddingLeft: ring_size.mobile ? 0 : 24,
            paddingRight: ring_size.mobile ? 0 : 24,
            minHeight: ring_size.mobile ? 0 : 900,
            flexDirection: ring_size.mobile ? "column" : "row",
            justifyContent: "stretched",
            alignItems: "stretched",
          }}
        >
          <c.Example
            mobile={ring_size.mobile}
            ratioDrift={ring_size.ratioDrift}
            left={1}
          />

          <s.SpacerLarge />
          <BodyWrapper
            mobile={ring_size.mobile}
            image={
              ring_size.mobile ? "/config/images/round_corners2.png" : null
            }
          >
            <ResponsiveWrapper
              flex={10}
              style={{
                paddingTop: ring_size.mobile ? 55 : ring_size.paddingTop,
                paddingRight: ring_size.mobile ? 24 : 0,
                paddingLeft: ring_size.mobile ? 24 : 0,
                paddingBottom: ring_size.mobile ? 60 : 0,
              }}
            >
              <c.TextTitleR
                sz={ring_size}
                style={{
                  fontWeight: "bold",
                }}
              >
                {data.totalSupply} / {CONFIG.MAX_SUPPLY}
              </c.TextTitleR>
              <s.SpacerMedium />
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                  <c.TextTitleR sz={ring_size}>
                    The sale has ended.
                  </c.TextTitleR>
                  <c.TextDescriptionR
                    sz={ring_size}
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    Find your {CONFIG.NFT_NAME}s on
                  </c.TextDescriptionR>
                  <s.SpacerSmall />
                  <c.StyledLinkR
                    sz={ring_size}
                    target={"_blank"}
                    href={CONFIG.MARKETPLACE_LINK}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {CONFIG.MARKETPLACE}
                  </c.StyledLinkR>
                </>
              ) : (
                <>
                  {ring_size.mobile ? (
                    <>
                      <s.SpacerSmall />
                      <c.TextDescriptionR
                        sz={ring_size}
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        1 Crypto Booze Ring costs {CONFIG.DISPLAY_COST}{" "}
                        {CONFIG.NETWORK.SYMBOL}.
                      </c.TextDescriptionR>
                      <c.TextDescriptionR
                        sz={ring_size}
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Excluding gas fees.
                      </c.TextDescriptionR>
                    </>
                  ) : null}
                  <s.SpacerSmall />
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <c.TextDescriptionR sz={ring_size}>
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </c.TextDescriptionR>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <c.TextDescriptionFeedbackR sz={ring_size}>
                            {blockchain.errorMsg}
                          </c.TextDescriptionFeedbackR>
                        </>
                      ) : null}
                      <s.SpacerSmall />
                      <c.StyledButtonR
                        sz={ring_size}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect(local));
                          getData();
                        }}
                      >
                        CONNECT
                      </c.StyledButtonR>
                    </s.Container>
                  ) : (
                    <>
                      <s.Container flex={0.1} ai={"center"} jc={"center"}>
                        {split_lines(feedback, ring_size)}
                      </s.Container>
                      {claimingNft ? (
                        <>
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <img
                              id={"minting-icon"}
                              alt={"Minting the Ring!"}
                              src={"/config/images/minting.png"}
                              style={{
                                width: ring_size.fontSize * 3.5 + "px",
                              }}
                            ></img>
                          </s.Container>
                        </>
                      ) : (
                        <>
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <c.StyledRoundButtonR
                              sz={ring_size}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementMintAmount();
                              }}
                            >
                              -
                            </c.StyledRoundButtonR>
                            <s.SpacerMedium />
                            <c.TextTitleR
                              sz={ring_size}
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {mintAmount}
                            </c.TextTitleR>
                            <s.SpacerMedium />
                            <c.StyledRoundButtonR
                              sz={ring_size}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                              }}
                            >
                              +
                            </c.StyledRoundButtonR>
                          </s.Container>
                          <s.SpacerSmall />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <c.StyledButtonR
                              disabled={claimingNft ? 1 : 0}
                              sz={ring_size}
                              onClick={(e) => {
                                e.preventDefault();
                                claimNFTs();
                                getData();
                              }}
                            >
                              {claimingNft ? "BUSY" : "BUY"}
                            </c.StyledButtonR>
                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </ResponsiveWrapper>
          </BodyWrapper>

          <s.SpacerLarge2 />
          <c.Example
            mobile={ring_size.mobile}
            ratioDrift={ring_size.ratioDrift}
            left={0}
          />
        </ResponsiveWrapper>
      </s.ContainerRing>
      <s.Container
        flex={1}
        jc={"center"}
        ai={"flext-start"}
        style={{
          width: ring_size.mobile ? "90%" : "auto",
          minWidth: ring_size.mobile ? 0 : 600,
        }}
      >
        <s.TextNotes>
          Please note: this is a demo dapp we have created for fun and self education about blockchain development.
        </s.TextNotes>
        <s.TextNotes>
          Contract address (CRING):{" "}
          <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
            {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
          </StyledLink>
        </s.TextNotes>
        <s.TextNotes>
          OpenSea collection:{" "}
          <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
          CryptoBoozeRings
          </StyledLink>
        </s.TextNotes>
      </s.Container>
      <s.SpacerLarge />
      {/* LAWN MOWER RACING! */}
      <s.Container
        flex={1}
        fd={ring_size.mobile ? "column" : "row"}
        ai={ring_size.mobile ? "center" : "flex-start"}
        style={{ padding: 24 }}
      >
        {/* todo: make it flex */}
        <img
          alt={"example"}
          src={
            ring_size.mobile
              ? "/config/images/lawn_mower_h.png"
              : "/config/images/lawn_mower_v.png"
          }
          style={{
            width: ring_size.mobile ? "100%" : 300,
          }}
        />
        <s.Container flex={1} ai={"flext-start"} fd="column">
          <s.TextTitle style={{ fontSize: 50, textAlign: "center" }}>
            Boost your Lawn Mower!
          </s.TextTitle>
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            An example of collaboration where owning a CryptoBoozeRing can bring some extra perks to another NFT collections owners in metaverse: 
          </s.TextTitle>     
          <s.SpacerMedium />     
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            Each CryptoBoozeRing will speed up your lawn mower in the upcoming
            racing game from CryptoDads.
          </s.TextTitle>
          <s.SpacerMedium />
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            Rings with the wings will give you even better boost and laser rings
            blow your socks off!
          </s.TextTitle>
          <s.SpacerMedium />
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            Let's fly!
          </s.TextTitle>
        </s.Container>
      </s.Container>
      <s.SpacerLarge />
      {/* WHO ARE WE? */}
      <s.Container
        flex={1}
        fd={ring_size.mobile ? "column" : "row"}
        ai={ring_size.mobile ? "center" : "flex-start"}
        style={{ padding: 24 }}
      >
        {/* todo: make it flex */}
        <img
          alt={"example"}
          src={
            ring_size.mobile
              ? "/config/images/Round_horizontal.png"
              : "/config/images/Round_vertical.png"
          }
          style={{
            width: ring_size.mobile ? "100%" : 300,
          }}
        />
        <s.Container flex={1} ai={"flext-start"} fd="column">
          <s.TextTitle style={{ fontSize: 50, textAlign: "center" }}>
            Who are we?{" "}
            <a href="#">
              <Icon name="twitter" color="#FFFFFF" width="100" height="100" />
            </a>
          </s.TextTitle>
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            I'm a software developer with passion for new techologies. 
            My wife is a graphic designed and helps me from tim to time make things prettier.
            This is a demo website to try blockchain NFT development. 
          </s.TextTitle>
        </s.Container>
      </s.Container>
      <s.SpacerLarge />
      <s.Container
        flex={1}
        fd={ring_size.mobile ? "column" : "row"}
        ai={ring_size.mobile ? "center" : "streched"}
        style={{ padding: 24 }}
      >
        {/* todo: make it flex */}
        <s.Container
          style={{ flex: "0 0 300px", backgroundPosition: "center" }}
          image={
            ring_size.mobile
              ? "/config/images/roadmap_h.png"
              : "/config/images/roadmap_v.png"
          }
        ></s.Container>
        <s.Container flex={1} ai={"flext-start"} fd="column">
          <s.TextTitle style={{ fontSize: 50, textAlign: "center" }}>
            The Roadmap
          </s.TextTitle>
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            0%: Keep you eyes open
          </s.TextTitle>
          <s.SpacerMedium />
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            20%: Get an idea
          </s.TextTitle>
          <s.SpacerMedium />
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            40%: Don't get scared or lazy
          </s.TextTitle>
          <s.SpacerMedium />
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            60%: Have fun: design it
          </s.TextTitle>
          <s.SpacerMedium />
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            80%: Have fun: implement/debug/test it
          </s.TextTitle>
          <s.SpacerMedium />
          <s.TextTitle
            style={{
              color: "var(--primary-text)",
              paddingLeft: ring_size.mobile ? 0 : 24,
            }}
          >
            100%: Have a cookie: now you learnt something new
          </s.TextTitle>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
