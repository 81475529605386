import styled from "styled-components";
import * as s from "../styles/globalStyles";

export const StyledLogo = styled.img`
  width: 100%;
`;

export const StyledExample = styled.img`
  display: flex;
  width: 20%;
  flex-flow: row nowrap;
  align-items: center;
`;

function Example(props) {
  const ratioDrift = props.ratioDrift;
  const ai = props.left ? "flex-end" : "flex-start";
  const image = props.left
    ? "/config/images/examples_left.gif"
    : "/config/images/examples_right.gif";
  if (!props.mobile && ratioDrift >= 1.6) {
    return (
      <s.Container style={{ flex: "0.4 1 10vw" }} ai={ai} jc={"center"}>
        <img src={image} style={{ width: "100%", maxWidth: "400px" }} />
      </s.Container>
    );
  }
  return null;
}

function Logo(props) {
  const logo1 = props.logo1;
  const logo2 = props.logo2;
  if (props.mobile) {
    return <StyledLogo alt={"CryptoBoozeRings"} src={logo2} />;
  }
  return (
    <s.Container>
      <img
        alt={"CryptoBoozeRings"}
        src={logo1}
        style={{ height: 120, minHeight: "10vh", paddingLeft: 44 }}
      />
    </s.Container>
  );
}

export const TextTitleR = styled.p`
  color: var(--primary-text);
  text-align: center;
  font-weight: 500;
  font-size: ${({ sz }) => (sz && !sz.mobile ? sz.fontSize + "px" : "40px")};
`;

export const TextDescriptionR = styled.p`
  color: var(--primary-text);
  font-size: ${({ sz }) => (sz && !sz.mobile ? sz.fontSize2 + "px" : "30px")};
  line-height: 1.6;
`;

export const TextDescriptionFeedbackR = styled.p`
  color: var(--accent-text);
  font-size: ${({ sz }) =>
    sz && !sz.mobile ? sz.fontSize2 * 0.8 + "px" : "25px"};
  line-height: 1.6;
`;

export const StyledLinkR = styled.a`
  color: var(--secondary);
  text-decoration: none;
  font-size: ${({ sz }) => (sz && !sz.mobile ? sz.fontSize + "px" : "40px")};
`;

export const StyledButtonR = styled.button`
  padding: 14px 20px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: ${({ sz }) => (sz && !sz.mobile ? sz.fontSize2 + "px" : "40px")};
  color: var(--secondary-text);
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButtonR = styled.button`
  padding: 14px 20px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: ${({ sz }) => (sz && !sz.mobile ? sz.fontSize2 + "px" : "40px")};
  color: var(--primary-text);
  cursor: pointer;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export { Example, Logo };
